import React from 'react';
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';

type QRCodeComponentProps = {
    text: string | undefined;
    secretKey: string;
};

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ text, secretKey }) => {
    if (text !== undefined) {
        const encryptText = (text: string) => {
            return CryptoJS.AES.encrypt(text, secretKey).toString();
        };

        const encryptedText = encryptText(text);

        // QRコードのSVGデータURLを生成する関数
        const getQRCodeDataURL = () => {
            const svg = document.querySelector('svg');
            if (svg) {
                const serializer = new XMLSerializer();
                const svgString = serializer.serializeToString(svg);
                return `data:image/svg+xml;base64,${btoa(svgString)}`;
            }
            return '';
        };

        const handleClick = (e: React.MouseEvent) => {
            e.preventDefault(); // デフォルトのリンク動作を無効にする
            const dataUrl = getQRCodeDataURL();
            if (dataUrl) {
                // 別タブでQRコード画像を開く
                const newWindow = window.open();
                if (newWindow) {
                    newWindow.document.write(`<img src="${dataUrl}" alt="QR Code" />`);
                    newWindow.document.close();
                }
            }
        };


        return (
            <div>
                <a href="#" onClick={handleClick}>
                    {/* QRコードをSVGとして描画 */}
                    <QRCode value={encryptedText} renderAs="svg" />
                </a>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default QRCodeComponent;
